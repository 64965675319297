.popupListItem {
  clear: both;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
  padding: 5px 12px;
  transition: all 0.3s;
  white-space: nowrap;
  -webkit-transition: all 0.3s;
}

.popupListItem:hover {
  background-color: #e6f7ff;
}

.popup li > i {
  margin-right: 8px;
}
