body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-table-small {
  border: none;
  border-radius: 0px;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none;
}

.ant-table-small.ant-table-bordered .ant-table-content {
  border-right: none;
}

.ant-table-empty .ant-table-body {
  overflow: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.FixedColumn {
  display: 'table';
  text-align: 'left';
  width: min-content;
}

.Resizer {
  box-sizing: border-box;
  background: rgb(168, 168, 168);
  opacity: 0.5;
  z-index: 1;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(214, 97, 97, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.setting-form {
  padding-left: 20px !important;
}

.vertical section {
  width: 100vh;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.vertical header {
  padding: 1rem;
  background: #eee;
}

.vertical footer {
  padding: 1rem;
  background: #eee;
}

.horizontal section {
  width: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.horizontal header {
  padding: 1rem;
  background: #eee;
}

.horizontal footer {
  padding: 1rem;
  background: #eee;
}

.parent {
  width: 100%;
  height: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.header {
  background: #aaa;
  height: 3rem;
  line-height: 3rem;
}
.wrapper {
  background: #ffa;
  margin: 5rem;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.yasqe__one_line_mode .CodeMirror-line {
  white-space: nowrap !important;
}

.yasqe__one_line_mode .CodeMirror-line > span {
  padding-right: 3em !important;
}
/*
 TinyMCE
 https://stackoverflow.com/questions/32349613/div-height-adjusting-doesnt-include-margin-of-its-content
*/
.mce-content-body {
  overflow: hidden;
}
/*.tox-tbtn button {
  height: auto;
  width: auto;
}*/
.mce-content-body {
  outline: 0px solid transparent;
}
