.ant-form-item-label {
  text-overflow: ellipsis;
}

.ant-uri {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tant-uri:hover {
  cursor: pointer;
}

.ant-uri:hover::after {
  content: attr(data-uri);
  position: absolute;
  right: 0;
  padding: 7px;
  transition: 3s;
  z-index: 1;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(182, 182, 184, 0.9);
  border-radius: 7px;
  animation: uri_appearance 0.3s;
}

@keyframes uri_appearance {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.9;
  }
}
