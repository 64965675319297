.label-nowrap {
  display: block;
  white-space: nowrap;
  font-weight: 500;
}
.input-margin {
  display: block;
  margin-left: 1em;
  min-width: 170px;
}
