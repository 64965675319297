.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.d-none {
    display: none;
}

.node {
    width: 150px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 0.5rem;
    background: #eeeeee;
    left: 60px;
}

.node-link {
    position: absolute;
    right: 160px;
    top: 10px;
}

.node-collapse {
    width: 20px;
    height: 20px;
    transition-duration: 0.3s;
    position: absolute;
    top: 20px;
    right: -30px;
    border-radius: 50%;
    background: #eeeeee;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.node-collapse:hover {
    background: bisque;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
